import { createAction, props, union } from '@ngrx/store';

export const loadQuickformOrderFeaturesAction = createAction(
  '[QuickformOrderFeatures] Load QuickformOrderFeatures',
  props<{ quickformOrderId: string }>()
);

export const loadQuickformOrderFeaturesSuccessAction = createAction(
  '[QuickformOrderFeatures] Load QuickformOrderFeatures Success',
  props<{ quickformOrderId: string }>()
);

const actions = union({
  /* eslint-disable @typescript-eslint/naming-convention */
  LoadQuickformOrderFeaturesAction: loadQuickformOrderFeaturesAction,
  LoadQuickformOrderFeaturesSuccessAction: loadQuickformOrderFeaturesSuccessAction
  /* eslint-enable @typescript-eslint/naming-convention */
});

export type QuickformOrderFeaturesAction = typeof actions;
