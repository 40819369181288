import { QuickFormOrderActionParameterType } from '../../models';

export class QuickFormOrderActionParameterTypeConverter {
  private static map = [
    {
      key: QuickFormOrderActionParameterType.AccountingNumber,
      value: 'ACCOUNTINGNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.AccountNumber,
      value: 'ACCOUNTNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.AccountTypAndeNumber,
      value: 'ACCOUNTTYPEANDNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.Address,
      value: 'ADDRESS'
    },
    {
      key: QuickFormOrderActionParameterType.AreaCode,
      value: 'AREACODE'
    },
    {
      key: QuickFormOrderActionParameterType.BatchNumber,
      value: 'BATCHNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.BgNumber,
      value: 'BGNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.CardNumber,
      value: 'CARDNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.CaseNumber,
      value: 'CASENUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.CompanyAndContractNumber,
      value: 'COMPANYANDCONTRACTNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.CompanyNumber,
      value: 'COMPANYNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.ContractEnd,
      value: 'CONTRACTEND'
    },
    {
      key: QuickFormOrderActionParameterType.ContractNumber,
      value: 'CONTRACTNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.CustomerNumber,
      value: 'CUSTOMERNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.CustomerNumberAndPhoneNumber,
      value: 'CUSTOMERNUMBERANDPHONENUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.Email,
      value: 'EMAIL'
    },
    {
      key: QuickFormOrderActionParameterType.FileNumber,
      value: 'FILENUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.IdNumber,
      value: 'IDNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.InsuranceNumber,
      value: 'INSURANCENUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.Login,
      value: 'LOGIN'
    },
    {
      key: QuickFormOrderActionParameterType.LotteryAndBatchNumber,
      value: 'LOTTERYANDBATCHNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.MagazineAndCustomerNumber,
      value: 'MAGAZINEANDCUSTOMERNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.MatriculationNumber,
      value: 'MATRICULATIONNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.MembershipNumber,
      value: 'MEMBERSHIPNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.MeterNumber,
      value: 'METERNUMMER'
    },
    {
      key: QuickFormOrderActionParameterType.MeterStand,
      value: 'METERSTAND'
    },
    {
      key: QuickFormOrderActionParameterType.PaymentNumber,
      value: 'PAYMENTNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.PensionNumber,
      value: 'PENSIONNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.PersonnelNumber,
      value: 'PERSONNELNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.PhoneNumber,
      value: 'PHONENUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.PlaceAndMembershipNumber,
      value: 'PLACEANDMEMBERSHIPNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.ProductAndContractNumber,
      value: 'PRODUCTANDCONTRACTNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.Reference,
      value: 'REFERENCE'
    },
    {
      key: QuickFormOrderActionParameterType.Region,
      value: 'REGION'
    },
    {
      key: QuickFormOrderActionParameterType.SchoolAndPlace,
      value: 'SCHOOLANDPLACE'
    },
    {
      key: QuickFormOrderActionParameterType.ServiceAndNumber,
      value: 'SERVICEANDNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.ServiceNumber,
      value: 'SERVICENUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.TaxNumber,
      value: 'TAXNUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.TelephoneNumber,
      value: 'TELEPHONENUMBER'
    },
    {
      key: QuickFormOrderActionParameterType.Undefined,
      value: 'UNDEFINED'
    },
    {
      key: QuickFormOrderActionParameterType.Username,
      value: 'USERNAME'
    },
    {
      key: QuickFormOrderActionParameterType.UsernameAndEmail,
      value: 'USERNAMEANDEMAIL'
    },
    {
      key: QuickFormOrderActionParameterType.WebSite,
      value: 'WEBSITE'
    }
  ];

  public static toStringDto(type: QuickFormOrderActionParameterType): string {
    try {
      const elt = QuickFormOrderActionParameterTypeConverter.map.find((x) => x.key === type);
      if (elt == null) {
        throw new Error(`The type QuickFormOrderActionParameterType ${type.toString()} is missing`);
      }
      return elt.value;
    } catch (err) {
      console.log(err);
    }
  }

  public static toType(value: string): QuickFormOrderActionParameterType {
    try {
      const elt = QuickFormOrderActionParameterTypeConverter.map.find((x) => x.value === value);
      if (elt == null) {
        throw new Error(`The type QuickFormOrderActionParameterType ${value.toString()} is missing`);
      }
      return elt.key;
    } catch (err) {
      console.log(err);
    }
  }
}
