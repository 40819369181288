import { Appointment } from '@order/models/appointment';
import { OrderState } from '../../company-portal/models/order-entry';
import { OrderCustomization } from './order-customization';

/**
 * The order's full featured database model.
 */
export interface Order extends OrderCustomization {
  pk: number;
  companyPk: number;
  state: OrderState;
  orderId: string;
  deceasedTitle: string;
  deceasedFirstNames: string;
  deceasedLastName: string;
  deceasedNameOfBirth: string;
  deceasedDateOfBirth: string;
  deceasedPlaceOfBirth: string;
  deceasedDateOfDeath: string;
  deceasedPlaceOfDeath: string;
  deceasedTown: string;
  deceasedZipCode: string;
  isSearchable: boolean;
  friendlyUrl: string;
  communityPassword: string;
  dateOfLock: string;
  visibleFrom: string;
  isDateOfLockFixed: boolean;
  reportedDateOfLock: string;
  isSocialMediaAvailable: boolean;
  eurofloristMemberId: string;
  showInUcloud: boolean;
  dateOfLockOverrideId: string;
  dateOfLockOverrideUntil: string;
  created: string;
  candlesCount: number;
  giftsCount: number;
  condolencesCount: number;
  publicImagesCount: number;
  appointments?: Appointment[];
}

export enum OrderStates {
  Premium = 4,
  Base = -1,
  NoMemorialPage = 0
}
