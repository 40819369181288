export enum ParameterHelpType {
  UNDEFINED = 'UNDEFINED',
  CONTRACTNUMBER = 'CONTRACTNUMBER',
  ACCOUNTINGNUMBER = 'ACCOUNTINGNUMBER',
  FILENUMBER = 'FILENUMBER',
  ADDRESS = 'ADDRESS',
  IDNUMBER = 'IDNUMBER',
  USERNAME = 'USERNAME',
  COMPANYNUMBER = 'COMPANYNUMBER',
  BGNUMBER = 'BGNUMBER',
  EMAIL = 'EMAIL',
  CASENUMBER = 'CASENUMBER',
  COMPANYANDCONTRACTNUMBER = 'COMPANYANDCONTRACTNUMBER',
  REFERENCE = 'REFERENCE',
  CARDNUMBER = 'CARDNUMBER',
  PAYMENTNUMBER = 'PAYMENTNUMBER',
  SERVICENUMBER = 'SERVICENUMBER',
  ACCOUNTTYPEANDNUMBER = 'ACCOUNTTYPEANDNUMBER',
  ACCOUNTNUMBER = 'ACCOUNTNUMBER',
  CUSTOMERNUMBER = 'CUSTOMERNUMBER',
  CUSTOMERNUMBERANDPHONENUMBER = 'CUSTOMERNUMBERANDPHONENUMBER',
  SERVICEANDNUMBER = 'SERVICEANDNUMBER',
  LOGIN = 'LOGIN',
  BATCHNUMBER = 'BATCHNUMBER',
  LOTTERYANDBATCHNUMBER = 'LOTTERYANDBATCHNUMBER',
  MATRICULATIONNUMBER = 'MATRICULATIONNUMBER',
  MEMBERSHIPNUMBER = 'MEMBERSHIPNUMBER',
  PHONENUMBER = 'PHONENUMBER',
  PLACEANDMEMBERSHIPNUMBER = 'PLACEANDMEMBERSHIPNUMBER',
  PENSIONNUMBER = 'PENSIONNUMBER',
  PERSONNELNUMBER = 'PERSONNELNUMBER',
  PRODUCTANDCONTRACTNUMBER = 'PRODUCTANDCONTRACTNUMBER',
  SCHOOLANDPLACE = 'SCHOOLANDPLACE',
  TAXNUMBER = 'TAXNUMBER',
  WEBSITE = 'WEBSITE',
  INSURANCENUMBER = 'INSURANCENUMBER',
  MAGAZINEANDCUSTOMERNUMBER = 'MAGAZINEANDCUSTOMERNUMBER',
  USERNAMEANDEMAIL = 'USERNAMEANDEMAIL',
  METERNUMMER = 'METERNUMMER',
  METERSTAND = 'METERSTAND',
  TELEPHONENUMBER = 'TELEPHONENUMBER',
  AREACODE = 'AREACODE',
  CONTRACTEND = 'CONTRACTEND',
  REGION = 'REGION'
}
