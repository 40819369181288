import { BuildSettings } from './build-settings.model';
import { AppSettings } from './settings.model';
import { Injectable } from '@angular/core';
import { GdprCookieBannerLibConfig } from 'gdpr-cookie-banner';
import { DemoMorticianSettings } from './settings-demo-mortician.model';
import { AuthSettings } from './auth-settings.model';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  public demoMorticianSettings: DemoMorticianSettings;

  public authSettings: AuthSettings;

  public buildSettings: BuildSettings;

  public settings: AppSettings;

  public cookieBannerConfig: GdprCookieBannerLibConfig;

  constructor() {
    this.settings = new AppSettings();
    this.authSettings = new AuthSettings();
    this.buildSettings = new BuildSettings();
    this.demoMorticianSettings = new DemoMorticianSettings();
    this.cookieBannerConfig = new GdprCookieBannerLibConfig();
  }
}

export class MockSettingsService {
  public get settings(): AppSettings {
    return {
      production: false,
      companyPageOrderAdminInviteTemplate: 'test',
      contentfulSpaceId: 'test',
      contentfulAccessToken: 'test',
      contentFulEnvironmentId: 'test',
      apiRoot: 'test',
      quickformApiRoot: 'test',
      imageAssetsHost: 'test',
      memoryBookUrl: 'test',
      funeralsDocumentApiUrl: 'test',
      bannedWordsApiUrl: 'test',
      hotjarKey: 'test',
      gaId: 'test',
      companyPageOrderAdminInviteGp1Template: '',
      cookieBannerConfigRevision: 'test',
      fontAwesomeKit: '',
      fontAwesomeKitUrl: '',
      funeralAddressServiceApiRoot: '',
      funeralMusicServiceApiRoot: '',
      isProductionEnvironment: false,
      precautionInfoApiUrl: '',
      qrCodeServiceUrl: '',
      useEnvironmentVariableConfig: false,
      funeralsDocumentUploadLimitMb: 5,
      precautionInfoDocumentUploadMaxFileSizeMb: 15,
      funeralDocumentMaxFileCount: 5,
      imageUploadMaxSizeMb: 4,
      imageUploadMaxSimultaneously: 15,
      maxImagesPerOrder: 50,
      supportedImageFileExt: '.jpg, .jpeg, .png',
      supportedImageFileMime: '.jpg, .jpeg, .png',
      impressumUrl: 'test',
      privacyPolicyUrl: 'test',
      trainingCampUrl: 'test',
      imageAssetRootStatic: 'test/',
      imageAssetStaticCandles: 'images/candles/',
      imageAssetStaticIndividual: 'individual/',
      imageAssetStaticLogo: 'logo.png',
      imageAssetStaticFavIco: 'favico.ico',
      componentCatalogEnabled: true,
      pensionNumberProductIds: '',
      appVersion: '1.0'
    } as AppSettings;
  }
}
