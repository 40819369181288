import { OrderMasterdata } from './../services/dtos/order-masterdata.interface';
export enum QuickFormOrderActionType {
  Undefined,
  DirectCancellation,
  Disclosure,
  DirectTransfert,
  DirectCancellationWithExpectedBalance
}

export enum AllowanceActionStatusType {
  Undefined,
  OnHold,
  InProcess,
  DisclosureInformed,
  DisclosureInformedAndConfirmed,
  DisclosureAnswered,
  DnvInformed,
  DnvInformedAndConfirmed,
  DnvActionRejected,
  DnvActionExecuted,
  NoActionSinceNoCustomer,
  Researching,
  DnvActionAnswered,
  DoNotDisturb,
  NeedsActionSinceCustomer,
  Discarded,
  ResearchError,
  CeProcessing,
  UnexepectedError,
  SendCustomerCancelFax,
  FaxFailed,
  Created
}
export enum ExpectedOspHitActionType {
  TransfertAndRetrieve
}
export enum PartnerCustomerStatusType {
  MightBeCustomer
}
// TODO: move this to it how file
export enum QuickFormLoginType {
  Undefined,
  ColumbaEmployee,
  MorticianEmployee,
  PartnerEmployee,
  Dependant,
  PotentialPartner,
  MorticianEnterpriseEmployee,
  Precaution,
  ThirdParty,
  ThirdPartyCreateMortician,
  ThirdPartyInsurance,
  System,
  Unknown
}
export enum QuickFormOrderActionCommentType {
  Undefined,
  DenialReason,
  CreatorsComment,
  Comment
}

export enum QuickFormOrderActionDocumentType {
  Unknown,
  Cancellation,
  CancellationConfirmation
}
export enum QuickFormFileType {
  Undefined,
  Pdf,
  Png,
  Html,
  Jpg,
  Eml,
  Json
}
export enum QuickFormOrderActionAnwserType {
  Accepted,
  Rejected
}

export enum QuickFormPrefilledDocumentType {
  Undefined,
  WidowPensionDeclaration,
  LossDeclaration,
  OriginalsNeededCoverLetter,
  CessionDeclaration
}
export interface QuickFormAccountInformation {
  bic: string;
  iban: string;
  recipientName: string;
}
export enum QuickFormMoneyRepartitionReceiverType {
  Mortician,
  Dependant,
  ThirdPerson,
  PartnerAccount,
  Undefined
}
export interface QuickFormMoneyRepartition {
  sum: number;
  receiverType: QuickFormMoneyRepartitionReceiverType;
  accountInformation: QuickFormAccountInformation;
}
export class QuickFormOrderActionDocument {
  id: string;

  type: QuickFormOrderActionDocumentType;

  fileType: QuickFormFileType;

  fileName: string;

  displayName: string;

  body: any;
}

export enum QuickFormOrderActionParameterType {
  Undefined,
  ContractNumber,
  AccountingNumber,
  FileNumber,
  Address,
  IdNumber,
  Username,
  CompanyNumber,
  BgNumber,
  Email,
  CaseNumber,
  CompanyAndContractNumber,
  Reference,
  CardNumber,
  PaymentNumber,
  ServiceNumber,
  AccountTypAndeNumber,
  AccountNumber,
  CustomerNumber,
  CustomerNumberAndPhoneNumber,
  ServiceAndNumber,
  Login,
  BatchNumber,
  LotteryAndBatchNumber,
  MatriculationNumber,
  MembershipNumber,
  PhoneNumber,
  PlaceAndMembershipNumber,
  PensionNumber,
  PersonnelNumber,
  ProductAndContractNumber,
  SchoolAndPlace,
  TaxNumber,
  WebSite,
  InsuranceNumber,
  MagazineAndCustomerNumber,
  UsernameAndEmail,
  MeterNumber,
  MeterStand,
  TelephoneNumber,
  AreaCode,
  ContractEnd,
  Region
}

export class QuickFormOrderActionParameter {
  type: QuickFormOrderActionParameterType;

  value: string;

  unknownValue: boolean;
}

export class QuickFormPrefilledDocument {
  name: string;

  type: QuickFormPrefilledDocumentType;
}

export class QuickFormOrderActionComment {
  isImage: boolean;

  value: string;

  commentType: QuickFormOrderActionCommentType;

  creatorType: QuickFormLoginType;

  createdAt: Date;

  updatedAt: Date;
}
export class QuickFormOrderActionProduct {
  name: string;

  brandName: string;

  companyName: string;
}
export enum QuickFormOrderActionLoadingStatus {
  Idle,
  Processing,
  Processed,
  Failed
}
export interface QuickFormOrderAction {
  id: string;
  type: QuickFormOrderActionType;
  statusChangedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  allowanceActionStatus: AllowanceActionStatusType;
  expectedOspHitAction: ExpectedOspHitActionType;
  partnerCustomerStatus: PartnerCustomerStatusType;
  product: QuickFormOrderActionProduct;
  domain: string;
  // ???
  message: string;

  comments: Array<QuickFormOrderActionComment>;
  documents: Array<QuickFormOrderActionDocument>;
  parameters: Array<QuickFormOrderActionParameter>;
  prefilledDocuments: Array<QuickFormPrefilledDocument>;
  moneyRepartitions: Array<QuickFormMoneyRepartition>;
  accountTransferRecipient: OrderMasterdata;
  loadStatus: QuickFormOrderActionLoadingStatus;
  // set when the user want to discard/answer an action. Allow to follow the state of the execution
  actionExecutionStatus: QuickFormOrderActionLoadingStatus;
}

export interface QuickFormOrderActionSendAnswer {
  orderActionId: string;
  answerType: QuickFormOrderActionAnwserType;
  file: File;
}
export interface QuickFormOrderActionProblemReport {
  orderActionId: string;
  message: string;
}
